<ng-container *ngIf="authenticatedUserService.isAuthenticated">
  <!-- <div style="display: flex; justify-content: center;"> -->

  <div class="adviser-welcome-container">
    <div class="adviser-dashboard-welcome">
      <h1 class="text-center mobile-heading">Welcome Adviser, you are in the Adviser role</h1>
      <div></div>
      <p class="adviser-dashboard-welcome__text">
        On this page, you can create a new fact find for a client, find an existing fact find or create a new adviser
        user.
      </p>
    </div>
    <div class="adviser-dashboard-image"></div>
  </div>

  <div>
    <ul class="main-navigation">
      <li *ngIf="hasManageTemplatesPermission">
        <a class="main-button main-button__block ff-button" routerLink="/templates">
          <div title="Manage Templates" alt="Manage Templates icon" class="main-navigation__icon manage-templates-icon">
          </div>
          <span class="mobile-heading">Manage Templates</span>
        </a>
      </li>
      <li *ngIf="hasManageOwnerPermission">
        <a class="main-button main-button__block ff-button" routerLink="/manage-owners">
          <div title="Manage Owners" alt="Manage Owners icon" class="main-navigation__icon create-user-icon"></div>
          <span class="mobile-heading">Manage Owners</span>
        </a>
      </li>
      <li *ngIf="hasCreateUserPermission">
        <a class="main-button main-button__block ff-button" routerLink="/create-user">
          <div title="Create User" alt="Create User icon" class="main-navigation__icon create-user-icon"></div>
          <span class="mobile-heading">Create User</span>
        </a>
      </li>
      <li>
        <a class="main-button main-button__block ff-button" routerLink="/create-fact-find">
          <div title="Create Fact Find" alt="Create Fact Find icon" class="main-navigation__icon create-fact-find-icon">
          </div>
          <span class="mobile-heading">Create Fact Find</span>
        </a>
      </li>
      <li>
        <a class="main-button main-button__block ff-button" routerLink="/enter-details">
          <div title="Enter Details" alt="Enter Details icon" class="main-navigation__icon enter-details-icon"></div>
          <span class="mobile-heading">Enter Details</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="customer-search customer-search-desktop">
    <div>
      <h1>
        Search for Fact Finds
      </h1>
      <p class="search_placeholder">
        Search by first name, last name, ID or external reference
      </p>
    </div>
    <input #query class="create-ff-form__input customer-search__search-bar" type="text" name=""
           id="customer-search-icon" placeholder="Search by first name, last name, ID or external reference"
           (keyup.enter)="fetchFactFindData()" [(ngModel)]="queryParams.searchTerm" />
    <button class="customer-search-icon" (click)="fetchFactFindData()">
      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.25" y="0.75" width="14.5" height="14.5" rx="7.25" stroke="#FFFEFE" stroke-width="1.5" />
        <path d="M14 13L17 16" stroke="#FFFEFE" stroke-width="1.5" stroke-linecap="round" />
      </svg>
    </button>
  </div>
  <div class="customer-search customer-search-mobile">
    <p>The search for fact finds functionality is only available for larger screens.</p>
  </div>

  <div *ngIf="hasQueried">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-pulse-sync"
                 [disableAnimation]="false">
      <p style="font-size: 1.25rem; color: white">Loading...</p>
    </ngx-spinner>
    <h2 style="color:black; margin-top: 3em">
      Showing {{filteredFactFindResults.length}} of {{totalCount}} Results found
    </h2>
    <div style="display:flex;justify-content: space-between;align-items: center;">
      <ul class="queried-table-filters">
        <li *ngFor="let option of filterOptions">
          <input type="checkbox" id="{{ 'checkbox-' + option.value }}" name="status" [(ngModel)]="option.checked"
                 [value]="option.value" (change)="toggleFilter(option.value)" />
          <label for="{{ 'checkbox-' + option.value }}">
            {{ option.label }}
          </label>
        </li>
      </ul>

      <div class="queried-table-sort">
        <span style="font-size: 0.75rem;">Sort by:</span>
        <div class="custom-select">
          <select [(ngModel)]="selectedSortOption" (ngModelChange)="sortData()">
            <option value="firstName">First name</option>
            <option value="lastName">Last name</option>
            <option value="externalReference">External reference</option>
            <option value="message">Unread messages</option>
          </select>
        </div>
        <div class="queried-table-sort-direction">
          <button (click)="toggleSortDirection()"
                  [ngClass]="{'asc': sortDirection === 'asc', 'desc': sortDirection === 'desc'}"></button>
        </div>
      </div>
    </div>

    <table class="queried-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Fact Find ID</th>
          <th>External Reference</th>
          <th>
            <div class="queried-table-message">
              <div title="Messages Icon Inactive" alt="Messages Icon Inactive"
                   class="messages-icon-inactive queried-table__icon">
              </div>
              Messages
            </div>
          </th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let factFind of filteredFactFindResults;" (click)="selectRow(factFind.id)"
            [class.selected]="factFind.id === selectedRowIndex">
          <td style="font-weight: bold;">{{ factFind.firstName }} {{factFind.lastName}}</td>
          <td>{{ factFind.id }}</td>
          <td>{{ factFind.externalReference }}</td>
          <td>
            <div *ngIf="factFind.message === 'Unread'" class="queried-table-message">
              <div title="Messages Icon Active" alt="Messages Icon Active"
                   class="messages-icon-active queried-table__icon">
              </div>
              <p style="font-size: 0.75rem;">{{ factFind.message }}</p>
            </div>
            <div *ngIf="factFind.message === 'Answered'" class="queried-table-message">
              <div title="Messages Icon Inactive" alt="Messages Icon Inactive"
                   class="messages-icon-inactive queried-table__icon">
              </div>
              <p style="font-size: 0.75rem;">{{ factFind.message }}</p>
            </div>
          </td>
          <td [ngStyle]="{ 'color': getColorBasedOnState(factFind.stateID) }"
              style="text-transform: uppercase; font-weight: bold;">
            {{ getState(factFind.stateID) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="queried-table-pagination">
      <button class="main-button main-button__block ff-button" [disabled]="isLastPage()" (click)="fetchPage(true)">
        Next
        page
      </button>
      <button class="main-button main-button__block ff-button" [disabled]="isFirstPage()"
              (click)="fetchPage(false)">
        Previous page
      </button>
    </div>
  </div>
</ng-container>

<div *ngIf="hasGenerateReportPermission" class="date-range-container">
  <h1>Download Reports</h1>
  <div class="date-pickers">
    <div class="date-picker">
      <label for="start">Start date:</label>
      <input id="start" type="date" [(ngModel)]="startDate" [attr.max]="endDate">
    </div>

    <div class="date-picker">
      <label for="end">End date:</label>
      <input id="end" type="date" [(ngModel)]="endDate" [attr.min]="startDate">
    </div>
  </div>

  <div class="download-buttons">
    <button (click)="getFactFindEditsData()">Edits Data</button>
    <button (click)="getFactFindAccessData()">Access Data</button>
  </div>
</div>
