import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { FactFind } from './FactFind';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FactFindService {
  constructor(
    private httpClient: HttpClient,
    private authenticatedUserService: AuthenticatedUserService
  ) {
  }

  public registerEventHandler(){
    this.authenticatedUserService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.factFinds = [];

        this.factFind = null;

        return;
      }

      void this.forcedInitialize();
    });
  }

  public factFinds: FactFind[];
  public factFind$ = new BehaviorSubject<FactFind | null>(null);

  get factFind(): FactFind | null {
    return this.factFind$.value;
  }

  set factFind(value: FactFind | null) {
    this.factFind$.next(value);
  }

  get factFindId(): number {
    if (this.factFind === null) throw Error('No fact find selected.');

    return this.factFind.id;
  }

  private isInitialized = false;

  async initialize(factFindId: number | null = null) {
    if (this.isInitialized && !factFindId) return true;

    await this.forcedInitialize(factFindId);

    return true;
  }

  public loading: Promise<void>;
  private loadingResolver: () => void;

  private async forcedInitialize(factFindId: number | null = null) {
    this.loading = new Promise<void>((resolve)=>{this.loadingResolver = resolve});

    this.isInitialized = true;

    const pageNumber = 1;
    const pageSize = 100;
    let params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize);

    if (factFindId) params = params.append('searchTerm', factFindId);
    
    this.factFinds = await firstValueFrom(this.httpClient.get<FactFind[]>('api/factfinds', { params }));

    const defaultFactFind = this.factFinds[0];

    this.factFind = factFindId ? this.factFinds.find((x) => x.id === factFindId) ?? defaultFactFind : defaultFactFind;

    this.loadingResolver();
  }
}
