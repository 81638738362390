import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticatedUserService } from '../authenticated-user.service';
import { FactFindService } from '../enter-details/factFind.service';
import { FactFindStatuses } from '../enums/dbo.FactFindStatus';
import { NgxSpinnerService } from 'ngx-spinner';
import { Permissions } from '../enums/dbo.Permission';
import { firstValueFrom } from 'rxjs';

// eslint-disable-next-line @angular-eslint/prefer-standalone, @angular-eslint/prefer-standalone-component
@Component({
  selector: 'adviser-dashboard',
  templateUrl: './adviser-dashboard.component.html',
  styleUrl: './adviser-dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdviserDashboardComponent implements OnInit {
  // eslint-disable-next-line max-params
  constructor(
    public authenticatedUserService: AuthenticatedUserService,
    public factFindService: FactFindService,
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.factFindService.factFind$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
    
    this.hasManageTemplatesPermission = this.authenticatedUserService.hasOwnerPermission(Permissions.ManageTemplates);
    this.hasCreateUserPermission = this.authenticatedUserService.hasOwnerPermission(Permissions.CreateUser);
    this.hasGenerateReportPermission = this.authenticatedUserService.hasOwnerPermission(Permissions.GenerateAuditReport);
    this.hasManageOwnerPermission = this.authenticatedUserService.hasOwnerPermission(Permissions.ManageOwners);
  }

  filterOptions = [
    { label: 'All', value: 'All', checked: true },
    { label: 'Created', value: 'Created', checked: false },
    { label: 'Submitted', value: 'Submitted', checked: false },
    { label: 'Archived', value: 'Archived', checked: false },
    { label: 'Published', value: 'Published', checked: false },
    { label: 'Deleted', value: 'Deleted', checked: false }
  ];

  private static readonly FIRST_PAGE = 1;

  hasManageTemplatesPermission = false;
  hasCreateUserPermission = false;
  hasGenerateReportPermission = false;
  hasManageOwnerPermission = false;
  factFindResults: FactFindResult[] = [];
  totalCount: number;
  filteredFactFindResults: FactFindResult[] = [];
  selectedFilters: string[] = ['All'];
  hasQueried = false;
  isDataLoaded = false;
  sortDirection = "asc";
  queryParams: {
    searchTerm: string | null;
    currentPage: number;
    pageSize: number;
  } = {
    searchTerm: null,
    currentPage: 1,
    pageSize: 50
  };

  selectedSortOption: keyof FactFindResult = 'firstName';

  sortData(): FactFindResult[] {
    return this.filteredFactFindResults.sort(this.getComparer());
  }
  
  getComparer(): (a: FactFindResult, b: FactFindResult) => number {
    // eslint-disable-next-line max-statements, complexity
    return (a, b) => {
      const selectedOption = this.selectedSortOption;
      const valueA = a[selectedOption];
      const valueB = b[selectedOption];

      const aIsGreater = 1;
      // If either value is null, place it at the bottom

      if (valueA === null) return aIsGreater;
      const bIsGreater = -1;
      if (valueB === null) return bIsGreater;

      if (this.sortDirection === "asc") {
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (valueA).localeCompare(valueB);
        }
        return (valueB as number) - (valueA as number);

      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return (valueB).localeCompare(valueA);
      }
      return (valueB as number) - (valueA as number);
    };
  }

  // eslint-disable-next-line class-methods-use-this -- Angular template requires instance method.
  getState(status: FactFindStatuses): string {
    return FactFindStatuses[status];
  }

  // eslint-disable-next-line class-methods-use-this -- Angular template requires instance method.
  getColorBasedOnState(stateID: FactFindStatuses): string {
    switch (stateID) {
      case FactFindStatuses.Created:
        return "#121827" // black
      case FactFindStatuses.Submitted:
        return "#55B28F" // green            
      case FactFindStatuses.Published:
        return "#569FDF" // blue
      case FactFindStatuses.Archived:
        return "#6C7180" // grey
      case FactFindStatuses.Deleted:
        return "#D32828" // red
      default:
        return "#121827" // black
    }
  }

  selectedRowIndex: number;
  async selectRow(factFindID: number) : Promise<void> {
    this.selectedRowIndex = factFindID;
    await this.factFindService.initialize(factFindID); // update selected factfind
  }

  // eslint-disable-next-line max-statements
  async toggleFilter(value: string): Promise<void> {
      const notFound = -1;

    if (value === 'All') {
      this.filterOptions.forEach(option => (option.checked = option.value === 'All'));
      this.selectedFilters = [];
    } else {
      const allOption = this.filterOptions.find(option => option.value === 'All');
      if (allOption) {
        allOption.checked = false;
      }

      const allIndex = this.selectedFilters.indexOf("All");
      
      if (allIndex !== notFound) {
        const deleteOneElement = 1;
        this.selectedFilters.splice(allIndex, deleteOneElement);}
    }

    const index = this.selectedFilters.indexOf(value);
    if (index === notFound) {
      this.selectedFilters.push(value);
    } else {
      const removeOneElement = 1;
      this.selectedFilters.splice(index, removeOneElement);
    }

    this.selectedRowIndex = -1;

    await this.fetchFactFindData();
  }

  toggleSortDirection(): void {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortData();
  }

  startDate: string | null = null;
  endDate: string | null = null;
  
  async getFactFindEditsData() {
    const dateRange = this.validateDateRange();
    await this.downloadCsvData('api/reports/factfind-edits-csv-data', 'factfind-edit-audits.csv', dateRange);
  }
  
  async getFactFindAccessData() {
    const dateRange = this.validateDateRange();
    await this.downloadCsvData('api/reports/factfind-access-csv-data', 'factfind-access-audits.csv', dateRange);
  }

  isFirstPage(): boolean {
    return this.queryParams.currentPage <= AdviserDashboardComponent.FIRST_PAGE;
  }

  isLastPage(): boolean {
    return !this.totalCount || this.queryParams.currentPage * this.queryParams.pageSize >= this.totalCount;
  }

  async fetchPage(isNext: boolean): Promise<void> {
    isNext ? this.queryParams.currentPage++ : this.queryParams.currentPage--;

    await this.fetchFactFindData(false);
  }

  // eslint-disable-next-line max-statements
  async fetchFactFindData(resetCurrentPage = true): Promise<void> {
    this.selectedRowIndex = -1;

    if (resetCurrentPage) {
      this.queryParams.currentPage = 1;
    }

    this.hasQueried = Boolean(this.queryParams.searchTerm);

    if (!this.hasQueried) return;

    let params = new HttpParams()
    .set('pageNumber', this.queryParams.currentPage)
    .set('pageSize', this.queryParams.pageSize);

    if (this.selectedFilters.filter(x => x !== 'All').length) params = params.append('statusFilters', this.selectedFilters.join('|'));

    await this.spinner.show();

    const result = await firstValueFrom(this.httpClient.get<FactFindQueryResult>(`api/factfinds/${this.queryParams.searchTerm}/query`, { params }));
    
    await this.spinner.hide();

    this.filteredFactFindResults = result.data;
    this.totalCount = result.totalCount;
    
    this.changeDetectorRef.detectChanges();
  }

  private async downloadCsvData(apiEndpoint: string, fileName: string, dateRange: { startDate: string, endDate: string }) {
    const csvData = await firstValueFrom(
      this.httpClient.post(apiEndpoint, dateRange, {
        responseType: 'blob'
      })
    );
  
    AdviserDashboardComponent.downloadFile(csvData, fileName);
  }

  private static downloadFile(data: Blob, fileName: string) {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
  }

  private validateDateRange() {
    if (!this.startDate || !this.endDate) throw new Error("Must include date range");

    return {
      // eslint-disable-next-line no-magic-numbers
      startDate: new Date(new Date(this.startDate).setHours(0, 0, 0, 0)).toISOString(),
      // eslint-disable-next-line no-magic-numbers
      endDate: new Date(new Date(this.endDate).setHours(23, 59, 59, 999)).toISOString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  }
}

interface FactFindResult {
  id: number;
  firstName: string;
  lastName: string;
  stateID: number;
  message: string | null;
  externalReference: string;
}

interface FactFindQueryResult {
  data: FactFindResult[];
  totalCount: number;
}
